// require('./bootstrap');

(function ($) {

    $(document).ready(function () {

        // Hero video START
        if ($('.hero-video').length) {
            if ($(document).width() < 768 || $(window).width() < 768 ) {
                $('.hero-video video').remove();
            } else {
                var videoUrl = "/video/boston-firearms-home-page.mp4";
                $('.hero-video video').attr('src', videoUrl);

                function handleVisibilityChange() {
                    let vp = document.getElementById("video-philosophy");

                    if (document.hidden) {
                            vp.pause();
                            $('#playButton .img').fadeIn();
                    } else {

                    }
                }
                document.addEventListener("visibilitychange", handleVisibilityChange, false);
            }

            $( window ).on( "resize", function() {
                if ($(document).width() < 768 || $(window).width() < 768 ) {
                    $('.hero-video video').remove();
                } else {
                    if($('.hero-video video').length < 1) {
                        $('<video id="id-hero-video" src="'+ videoUrl +'" autoplay="" muted="" loop=""></video>').appendTo('.hero-video');
                    }
                }
            });
        }


        if ($('.video-philosophy video').length) {
            var oVideo = document.getElementById('video-philosophy');
            var button = document.getElementById('playButton');
            var html = document.documentElement;

            button.addEventListener('click', function () {
                if (oVideo.paused) {
                    oVideo.play();
                    $('#playButton .img').fadeOut();
                } else {
                    oVideo.pause();
                    $('#playButton .img').fadeIn();
                }
            });

            oVideo.addEventListener(
                'ended',
                function () {
                    $('#playButton .img').fadeIn();
                },
                false
            );
        }
    // Hero video  END


    //    ! V3 START

    if ($('.articles-list--full--v3').length) {
        $('.articles-list--full--v3').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            autoplay: true,
            autoplaySpeed: 2000,
            variableWidth: true,
            responsive: [{
            breakpoint: 1470,
            settings: {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 3000
            },
            breakpoint: 500,
            settings: {
                arrows: false,
                autoplay: true,
                slidesToShow: 1,
                autoplaySpeed: 3000
            }
            }]
        });
    }

    if ($('.review .review-carousel-v3').length) {
        $('.review .review-carousel-v3').slick({
            dots: false,
            infinite: true,
            speed: 300,
            // autoplay: true,
            arrows: true,
            // autoplaySpeed: 3000,
            slidesToShow: 4,
            // slidesToScroll: 1,
            // variableWidth: true,
            // mobileFirst: true,
            accessibility: false,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            }]
        });
    }

    if ($('.articles-section-v3').length) {
        $('.articles-section-v3 .dot.dot-1').click(function(){
            $('.articles-section-v3.dot-2').removeClass('dot-2').addClass('dot-1');
            $('.articles-section-v3 .dot.dot-2').removeClass('active');
            $('.articles-section-v3 .dot.dot-1').addClass('active');
        });
        $('.articles-section-v3 .dot.dot-2').click(function(){
            $('.articles-section-v3.dot-1').removeClass('dot-1').addClass('dot-2');
            $('.articles-section-v3 .dot.dot-1').removeClass('active');
            $('.articles-section-v3 .dot.dot-2').addClass('active');
        });
    }

    if ($('.map-switcher-v3 .switch-tabs').length) {
        $('.map-switcher-v3 .switch-tab.tab-1').click(function(){
          $('.map-switcher-v3').toggleClass('multi');
          $('.tab-content.tab-2').removeClass('active');
          $('.tab-content.tab-1').addClass('active');
          $('.switch-tab.tab-2').removeClass('active');
          $('.switch-tab.tab-1').addClass('active');
        });
        $('.map-switcher-v3 .switch-tab.tab-2').click(function(){
          $('.map-switcher-v3').toggleClass('multi');
          $('.tab-content.tab-1').removeClass('active');
          $('.tab-content.tab-2').addClass('active');
          $('.switch-tab.tab-1').removeClass('active');
          $('.switch-tab.tab-2').addClass('active');
        });
      }

    if ($('.hiw-switcher-v3 .switch-tabs').length) {
        $('.hiw-switcher-v3 .switch-tab.tab-1').click(function(){
            $('.col-img .tab-3').removeClass('active');
            $('.col-img .tab-2').removeClass('active');
            $('.col-img .tab-1').addClass('active');
            $('.switch-tab.tab-3').removeClass('active');
            $('.switch-tab.tab-2').removeClass('active');
            $('.switch-tab.tab-1').addClass('active');
        });
        $('.hiw-switcher-v3 .switch-tab.tab-2').click(function(){
            $('.col-img .tab-3').removeClass('active');
            $('.col-img .tab-1').removeClass('active');
            $('.col-img .tab-2').addClass('active');
            $('.switch-tab.tab-3').removeClass('active');
            $('.switch-tab.tab-1').removeClass('active');
            $('.switch-tab.tab-2').addClass('active');
        });
        $('.hiw-switcher-v3 .switch-tab.tab-3').click(function(){
            $('.col-img .tab-1').removeClass('active');
            $('.col-img .tab-2').removeClass('active');
            $('.col-img .tab-3').addClass('active');
            $('.switch-tab.tab-1').removeClass('active');
            $('.switch-tab.tab-2').removeClass('active');
            $('.switch-tab.tab-3').addClass('active');
        });
    }

    if ($('.switch-tabs--payment').length) {
        $('.switch-tabs--payment .switch-tab.tab-1').click(function(){
            $('.tab-content.tab-2').removeClass('active');
            $('.tab-content.tab-1').addClass('active');
            $('.switch-tab.tab-2').removeClass('active');
            $('.switch-tab.tab-1').addClass('active');
        });
        $('.switch-tabs--payment .switch-tab.tab-2').click(function(){
            $('.tab-content.tab-1').removeClass('active');
            $('.tab-content.tab-2').addClass('active');
            $('.switch-tab.tab-1').removeClass('active');
            $('.switch-tab.tab-2').addClass('active');
        });
    }

    if ($('.section-payment--all-forms .spa--tabs').length) {
        $('.section-payment--all-forms .spa--tab.tab-1').click(function(){
          $('.spa--tabs--content .tab-3').removeClass('active');
          $('.spa--tabs--content .tab-2').removeClass('active');
          $('.spa--tabs--content .tab-1').addClass('active');
          $('.spa--tabs .tab-3').removeClass('active');
          $('.spa--tabs .tab-2').removeClass('active');
          $('.spa--tabs .tab-1').addClass('active');
        });
        $('.section-payment--all-forms .spa--tab.tab-2').click(function(){
          $('.spa--tabs--content .tab-3').removeClass('active');
          $('.spa--tabs--content .tab-1').removeClass('active');
          $('.spa--tabs--content .tab-2').addClass('active');
          $('.spa--tabs .tab-3').removeClass('active');
          $('.spa--tabs .tab-1').removeClass('active');
          $('.spa--tabs .tab-2').addClass('active');
        });
        $('.section-payment--all-forms .spa--tab.tab-3').click(function(){
          $('.spa--tabs--content .tab-1').removeClass('active');
          $('.spa--tabs--content .tab-2').removeClass('active');
          $('.spa--tabs--content .tab-3').addClass('active');
          $('.spa--tabs .tab-1').removeClass('active');
          $('.spa--tabs .tab-2').removeClass('active');
          $('.spa--tabs .tab-3').addClass('active');
        });
    }

        if ($('.get-started-v3 .item').length) {
            $('.get-started-v3 .item .togglr').click(function(){
                $(this).closest('.item').toggleClass('full');
            });
        }


        // Mobile menu V3 START
        if ($('.menu-mobile-v3').length) {
            $('.menu-mobile-opener-v3').click(function () {
                $('.menu-mobile-v3').toggle();
                $('.menu-mobile-opener-v3').toggle();
                $('body').addClass('scroll-off');
                $('.promo-banner').hide();
                $('.page-promo--check').removeClass('page-promo');
            });
            $('.menu-mobile-close-v3').click(function () {
                $('.menu-mobile-v3').toggle();
                $('.menu-mobile-opener-v3').toggle();
                $('body').removeClass('scroll-off');
                $('.promo-banner').show();
                $('.page-promo--check').addClass('page-promo');
            });
        }
        // Mobile menu V3 END
        //    ! V3 END


        if ($('.faq-section-v3').length) {
            $('.faq-section-v3 .faq .title').click(function () {
                $('.faq-section-v3 .faq .description').slideUp();
                $('.faq.active').removeClass('active');

                if ($(this).next().css('display') == 'none') {
                    $(this).next().slideDown();

                    setTimeout(() => {
                        $(this).parent().addClass('active');
                    }, 100);
                }

            });
        }

        if ($('.faq-section-v32').length) {
            $('.faq-section-v32 .faq .title').click(function () {
                $('.faq-section-v32 .faq .description').slideUp();
                $('.faq.active').removeClass('active');

                if ($(this).next().css('display') == 'none') {
                    $(this).next().slideDown();

                    setTimeout(() => {
                        $(this).parent().addClass('active');
                    }, 100);
                }

            });
        }
        //    ! V3-2 END



        // Promo START
        if ($('.promo-banner ').length) {
            $('.promo-close').click(function () {
                $('.promo-banner').hide();
                $('.page-promo').removeClass('page-promo');
            });
        }
        // Promo END

        // Mobile menu START
        if ($('.menu-mobile').length) {
            $('.menu-mobile-opener').click(function () {
                $('.menu-mobile').toggle();
                $('.menu-mobile-opener').toggle();
                $('body').addClass('scroll-off');
                $('.promo-banner').hide();
                $('.page-promo--check').removeClass('page-promo');
            });
            $('.menu-mobile-close').click(function () {
                $('.menu-mobile').toggle();
                $('.menu-mobile-opener').toggle();
                $('body').removeClass('scroll-off');
                $('.promo-banner').show();
                $('.page-promo--check').addClass('page-promo');
            });
        }
        // Mobile menu END

        // SMS mobile section START
        if ($('.sms-mobile').length) {
            $('body').on('click', '.sms-mobile-opener', function () {
                $('.sms-mobile').toggle();
                $('.sms-mobile-opener').toggle();
                $('body').addClass('scroll-off');
                $('.menu-mobile-opener').hide();
            });
            $('.sms-mobile-close').click(function () {
                $('.sms-mobile').toggle();
                $('.sms-mobile-opener').toggle();
                $('body').removeClass('scroll-off');
                $('.menu-mobile-opener').show();
            });
        }
        // SMS mobile section END

        if ($('.info-switcher--home').length) {
            $('.info-switcher--home .info-section-switch').click(function () {
                if ($(this).hasClass('active')) {
                    // nothing
                } else {
                    $('.info-section-switch').toggleClass('active');
                    $('.info-section').toggle();
                }
            });
        }

        // Date & Phone field Mask START
        // TODO: Date Field -->
        if ($('#date-selector--mask').length) {
            $('#date-selector--mask').mask('99/99/9999', {
                // placeholder: 'MM/DD/YYYY',
            });
        }
        // TODO: Phone Field -->
        if ($('#phone--mask input, input.phone-field').length) {
            $('#phone--mask input, input.phone-field').mask('(999) 999-9999', {
                // placeholder: 'Enter Phone Number',
            });
        }
        // Date & Phone field Mask END

        // Header sticky START

        var scrollHeight = 200;

        if ($('.page-payment').length) {
            var scrollHeight = 10;
        }
        //
        if ($('.header-sticky').length) {
            $(window).scroll(function () {
                if ($(window).scrollTop() >= scrollHeight) {
                    $('body').addClass('body--fixed-header');
                    $('.header-sticky').addClass('fixed-header');

                    if ($('.page-payment').length) {
                        $('.page-payment').addClass('page--fixed-header');
                    }
                }
                if ($(window).scrollTop() < scrollHeight) {
                    $('body').removeClass('body--fixed-header');
                    $('.header-sticky').removeClass('fixed-header');

                    if ($('.page-payment').length) {
                        $('.page-payment').removeClass('page--fixed-header');
                    }
                }
            });
        }
        // Header sticky END

        if ($('.field-block--select').length) {
            // Dropdown arrow START
            $('body').click(function () {
                $('.field-block--select').removeClass('active');
            });

            $('.field-block--select').click(function (event) {
                event.stopPropagation();
                if ($(this).hasClass('active')) {
                    $('.field-block--select').removeClass('active');
                } else {
                    $('.field-block--select').removeClass('active');
                    $(this).addClass('active');
                }
            });
            // Dropdown arrow END
        }


        if ($('.faq-section').length) {
            console.log('FAQ exists');
            $('.faq-section .faq .title').click(function () {
                console.log('FAQ title clicked');
                $('.faq-section .faq .description').slideUp();
                $('.faq-section .faq .title').removeClass('open');
                if ($(this).next().css('display') == 'none') {
                    $(this).next().slideDown();
                    $(this).addClass('open');
                }
            });
        }

        if ($('.gr-carousel').length) {
            $('.gr-carousel').slick({
                dots: false,
                infinite: false,
                speed: 300,
                slidesToShow: 3,
                centerMode: true,
                variableWidth: true,
            });
        }

        if ($('.review-carousel').length) {
            $('.review-carousel').slick({
                dots: true,
                // infinite: false,
                infinite: true,
                speed: 300,
                autoplay: true,
                autoplaySpeed: 3000,
                // slidesToShow: 1,
                centerMode: true,
                variableWidth: true,

                responsive: [
                    {
                        breakpoint: 1470,
                        settings: {
                            arrows: true,
                            centerMode: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                        },
                    },
                ],
            });
        }

        if ($('.courses-grid').length) {
            var slidesToShow = 3;
            if ($('.courses-grid .item').length == 2) {
                slidesToShow = 2;
                $('.courses-grid').addClass('courses-grid--2cols');
            }
            if ($('.courses-grid .item').length == 1) {
                slidesToShow = 1;
                $('.courses-grid').addClass('courses-grid--1col');
            }

            $('.courses-grid').slick({
                dots: false,
                // infinite: false,
                infinite: true,
                speed: 300,
                autoplay: true,
                autoplaySpeed: 3000,
                // slidesToShow: 3,
                // slidesToShow: 2,
                slidesToShow: slidesToShow,
                // centerMode: true,
                // centerPadding: '40px',
                variableWidth: false,

                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            autoplay: true,
                            autoplaySpeed: 3000,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            // centerPadding: '40px',
                            slidesToShow: 1,
                            autoplay: true,
                            autoplaySpeed: 3000,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            // centerPadding: '40px',
                            slidesToShow: 1,
                            variableWidth: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                        },
                    },
                ],
            });
        }

        // course-info-slider
        if ($('.course-info-slider').length) {
            $('.course-info-slider').slick({
                dots: false,
                //arrows: false,
                infinite: true,
                speed: 300,
                autoplaySpeed: 3000,
                slidesToShow: 1,
            });
        }
    });

    // dropdown
    $(document).ready(function(){
        ///click container lang menu
        $(".lang-menu").click(
            function() {
                $(".lang-menu ul").stop().slideToggle(100);
            },
            function() {
                $(".lang-menu ul").stop().slideToggle(100);
            }
        );
        // click languages
        $(".lang-menu ul li").on("click", function() {
            let lang = $(this).find('.lang-item').text();
            let langCode = '';
            let flag = $(this).find('.flag-ico use').attr('xlink:href');

            switch (lang) {
                case 'English':
                    langCode = 'EN';
                    break;
                case 'Spanish':
                    langCode = 'SP';
                    break;
                case 'Portuguese':
                    langCode = 'Pt';
                    break;
                case 'Chinese':
                    langCode = 'Cn';
                    break;
                default:
                    langCode = lang;
                    break;
            }
            $(".main-lang .name-lang").text(langCode);
            $(".main-lang .flag-ico use").attr('xlink:href', flag);
        });

        // sets the language and flag depending on the link
        let langUrl = window.location.href;
        if (langUrl.endsWith('video-spanish')) {
            $(".main-lang .name-lang").text('SP');
            $(".main-lang .flag-ico use").attr('xlink:href', '#sp-ico');
        } else if (langUrl.endsWith('video-portuguese')) {
            $(".main-lang .name-lang").text('Pt');
            $(".main-lang .flag-ico use").attr('xlink:href', '#pt-ico');
        } else if (langUrl.endsWith('video-mandarin')) {
            $(".main-lang .name-lang").text('Cn');
            $(".main-lang .flag-ico use").attr('xlink:href', '#cn-ico');
        }

    });
    $(document).click(function(event) {
        let $target = $(event.target);
        if(!$target.closest('.lang-menu').length) {
          $('.lang-menu .dropdown').hide();
        }
    });

    // Mobile menu
    $(document).on('click', '.mobile-button', function() {
        $(this).toggleClass('active');
        $('.course-sidebar').toggleClass('open');
    });

    //select-course-pu version - anchor
    // .header-transparent .header-transparent-content .menu-row.home .button,
    // .header-first-page .header-first-content .menu-row .button,
    // .book-now-section .book-now-mobile-opener,
    // .header-transparent .header-transparent-content .menu-mobile .menu-col .button,
    // .hero-home .hero-text-wrapper .hero-text .hero-text---btns--mobile .signup,
    // .header-first-page .header-first-content .menu-mobile .menu-col .button
    // $(document).on('click', `
    // .articles-v3--full-carousel .article-btn-row .button, 
    // .articles-section-v3 .article-btn-row .button`, 
    // function(event) {
    //     if ($('.course-info-section').length > 0) {
    //         event.preventDefault();
    //         $('html, body').animate({
    //             scrollTop: $('.course-info-section').offset().top - 100
    //         }, 800);
    //     }
    // });

    $(document).on('click', `.hero-home .hero-text-wrapper .hero-text .hero-text---btns--mobile .signup`, 
    function(event) {
        if ($('.course-info-slider-section').length > 0) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $('.course-info-slider-section').offset().top - 100
            }, 800);
        }
    });

    // close mobile menu on click anchor button
    if ($('.menu-mobile').length) {
        $(`.header-transparent .header-transparent-content .menu-mobile .menu-col .button,
        .header-first-page .header-first-content .menu-mobile .menu-col .button`).click(function () {
            $('.menu-mobile').toggle();
            $('.menu-mobile-opener').toggle();
            $('body').removeClass('scroll-off');
            $('.promo-banner').show();
            $('.page-promo--check').addClass('page-promo');
        });
    }

    // show “chat with us” bubble when mobile menu is open
    if ($('.menu-mobile').length) {
        $(`.menu-mobile-opener`).click(function () {
            $('.sms-mobile-opener').addClass('show');
        });
        $(`.menu-mobile-close`).click(function () {
            $('.sms-mobile-opener').removeClass('show');
        });
    }

    // change URL button in course tab
    // $('.course-info-section .switch-1 .button').attr('href','/checkout/online/basic');
    // $('.course-info-section .switch-2 .button').attr('href','/checkout/online/multi');

    // change URL button
    // if ($('.course-info-section').length < 1) {
    //     $('.header-first-page .header-first-content .menu-row .button').attr('href','/checkout/online/basic');
    // }

    // drop down header button
    $(document).on('click', '.buttonDropDownSignUpJs', function(event) {
        event.preventDefault();
        $(this).toggleClass('active');
        $('.dropDownSignUpJs').toggleClass('open');
    });
    // drop down mobile fixed button
    $(document).on('click', '.mobButtonDropDownSignUpJs', function(event) {
        event.preventDefault();
        $(this).toggleClass('active');
        $('.mobDropDownSignUpJs').toggleClass('open');
    });
    // drop down buttonTranslateJs button
    $(document).on('click', '.buttonTranslateJs', function(event) {
        event.preventDefault();
        $(this).toggleClass('active');
        $('.dropDownTranslateJs').toggleClass('open');
    });
    //click outside
    $(document).click(function(event) { 
        var $target = $(event.target);
        if(!$target.closest('.dropDownSignUpJs').length && !$target.closest('.buttonDropDownSignUpJs').length) {
          $('.dropDownSignUpJs').removeClass('open');
          $('.buttonDropDownSignUpJs').removeClass('active');
        }

        if(!$target.closest('.mobDropDownSignUpJs').length && !$target.closest('.mobButtonDropDownSignUpJs').length) {
            $('.mobDropDownSignUpJs').removeClass('open');
            $('.mobButtonDropDownSignUpJs').removeClass('active');
        }

        if(!$target.closest('.dropDownTranslateJs').length && !$target.closest('.buttonTranslateJs').length) {
            $('.dropDownTranslateJs').removeClass('open');
            $('.buttonTranslateJs').removeClass('active');
        }
    });

    // Banner Urgent
    if ($('.urgent-msg').length) {
        $('.urgent-msg .close-btn').click(function () {
            $(this).closest('.urgent-msg').hide();
            $('.after--header-first--small').removeClass('pd-msg');
            $('.faq-section--responsive').removeClass('pd-msg');
        });

        $('.after--header-first--small').addClass('pd-msg');
        $('.faq-section--responsive').addClass('pd-msg');
    }

})(jQuery);
